<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem>
        <span>申请单号：</span>
        <i-input v-model="queryFrom.order_number" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>客户名称：</span>
        <Select class="iviewIptWidth250 marginRight60" v-model="supplierIndex" clearable filterable>
          <Option v-for="(item, index) in supplieList" :value="index" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <!--      <FormItem>-->
      <!--        <span>申请时间：</span>-->
      <!--        <DatePicker :editable='false'  type="daterange" class="iviewIptWidth250 marginRight60" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime"></DatePicker>-->
      <!--      </FormItem>-->
      <FormItem>
        <span class="label">下单时间：</span>
        <DatePicker class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker>
        <span class="choseTimeZhi">至</span>
        <DatePicker :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <FormItem>
        <span>申请类型：</span>
        <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.apply_type" clearable>
          <Option v-for="(item, index) in applyTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span style="margin-left: 33px;">状态：</span>
        <Select class="iviewIptWidth250" v-model="queryFrom.purchase_apply_state" clearable>
          <Option v-for="(item, index) in applyStateList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add" v-if="insertAble">新增</span>
      </FormItem>
    </Form>
    <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录">
      <div slot="summary" class="summary" v-if="listData.length > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ totalNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totalMoney }}</span>
        </div>
      </div>
    </Table>
    <!--    审核-->
    <Modal v-model="reviewStatus" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>订单是否审核通过？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="approved(1)">通过</span>
          <span class="pageBtn finger btnCancle" @click="approved(0)">不通过</span>
        </div>
      </div>
    </Modal>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'purchaseRequisitionConsign',
  components: {
    Table,
    TipsComponent,
  },
  data() {
    return {
      paging: null,
      clickId: null,
      status: false,
      template_type: '',
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 1,
      typeList: [],
      options: {},
      tipStatus: false,
      totalMoney: '',
      totalNum: '',
      supplierIndex: -1,
      queryFrom: {
        page: 1,
        rows: 10,
        order_number: '',
        customer_id: '',
        customer_type: '',
        start_time: '',
        end_time: '',
        purchase_apply_state: '',
        apply_type: '',
      },
      applyStateList: [
        {
          value: 1,
          name: '草稿',
        },
        {
          value: 2,
          name: '待审核',
        },
        {
          value: 3,
          name: '已审核',
        },
        {
          value: 4,
          name: '已删除',
        },
      ],
      applyTypeList: [
        {
          value: 1,
          name: '新增',
        },
        {
          value: 2,
          name: '转单',
        },
        {
          value: 3,
          name: '自动生成',
        },
      ],
      supplieList: [],
      orderNum: '',
      isLoad: true,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '申请单号',
          align: 'center',
          key: 'order_number',
          minWidth: 170,
          width: 170,
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.edit(param.row, 1)
                    },
                  },
                },
                param.row.order_number
              ),
            ])
          },
        },
        {
          title: '客户名称',
          align: 'center',
          key: 'customer_name',
          width: 270,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '产品',
          align: 'center',
          key: 'product_list',
          minWidth: 300,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.product_list
              ),
            ])
          },
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          width: 100,
        },
        {
          title: '金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '0')])
          },
          width: 140,
        },
        {
          title: '申请单时间',
          key: 'creatTime',
          align: 'center',
          sortable: 'custom',
          width: 190,
        },
        {
          title: '申请类型',
          key: 'apply_type_str',
          align: 'center',
          width: 110,
        },
        {
          title: '状态',
          key: 'purchase_apply_state_str',
          align: 'center',
          width: 110,
        },
        {
          title: '操作',
          align: 'center',
          width: 170,
          render: (h, param) => {
            // purchase_apply_state==1 是草稿     purchase_apply_state==2是待审核  purchase_apply_state==3是已审核
            if (param.row.purchase_apply_state == 1) {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.edit(param.row, 2)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '40px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (param.row.purchase_apply_state == '3' || param.row.purchase_apply_state == '2') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
            // else if (param.row.purchase_apply_state == 2) {
            //   return h('div', [
            //     h(
            //       'span',
            //       {
            //         style: {
            //           color: '#389AFC',
            //
            //           cursor: 'pointer',
            //         },
            //         on: {
            //           click: () => {
            //             this.review(param.row)
            //           },
            //         },
            //       },
            //       '审核'
            //     ),
            //     h(
            //       'span',
            //       {
            //         style: {
            //           color: '#389AFC',
            //
            //           cursor: 'pointer',
            //           marginLeft: '40px',
            //         },
            //         on: {
            //           click: () => {
            //             this.deleteRow(param.row)
            //           },
            //         },
            //       },
            //       '删除'
            //     ),
            //   ])
            // }
          },
        },
      ],
      listData: [],
      total: 0,
      reviewInfo: {}, // 暂存数据
      reviewStatus: false,
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  activated() {
    console.log(sessionStorage.getItem('updataCache'), '揍我了吗')
    if (sessionStorage.getItem('updataCache') == 0) {
      console.log(this.parse, '揍我了吗')
      this.queryList()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    if (this.$route.query.show) {
      this.queryFrom.purchase_apply_state = 2
    }
    this.getRightVisibal()
    this.querySupplierSelect()
  },
  methods: {
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$apiConsign.pruchaseApplyDetail.url}?id=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '采购申请单.xlsx'
        aLink.click()
      })
    },
    // 列表单号、时间排序
    sortChange(data) {
      console.log(data)
      if (data.key === 'creatTime') {
        this.queryFrom.sort_str = 'create_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    // 时间改变
    changeTime(e, num) {
      let that = this
      if (num == 1) {
        that.queryFrom.start_time = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.start_time) - 86400000
          },
        }
      } else {
        that.queryFrom.end_time = e
      }
    },
    // 获取客户名称
    querySupplierSelect() {
      this.$http
        .fetch(this.$apiConsign.customerList, true)
        .then(res => {
          this.supplieList = res.data
        })
        .then(() => {
          this.query()
        })
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.supplieList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.supplieList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.isLoad = true
      this.$http.get(this.$apiConsign.pruchaseApplyManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        this.totalNum = res.total_number
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    // 新增
    add() {
      this.$router.push({
        path: '/addPurchaseRequisitionConsign',
      })
    },
    // 编辑
    edit(row, num) {
      console.log(row)
      this.$router.push({
        path: '/editPurchaseRequisitionConsign',
        query: {
          id: row.id,
          state: row.apply_type_str,
          type: num, // 1 是查看 2是编辑
          triName: num == 1 ? '详情' : '编辑',
          order_number: row.order_number,
        },
      })
    },
    // 点击审核
    review(row) {
      this.reviewInfo = row
      this.reviewStatus = true
    },
    // 通过审核
    approved(num) {
      let data = {
        id: this.reviewInfo.id,
        check_result: num,
      }
      this.$http.put(this.$apiConsign.pruchaseApplyManage, data, true).then(res => {
        this.reviewStatus = false
        this.query()
      })
    },
    // 点击删除
    deleteRow(row) {
      this.reviewInfo = row
      this.tipStatus = true
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.pruchaseApplyManage, { id: this.reviewInfo.id }, true).then(res => {
        this.$Message.success('删除成功！')
        this.queryList()
        this.tipStatus = false
      })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.item {
  margin-bottom: 20px;
}
.marginRight60 {
  margin-right: 60px;
}
.reviewDiv {
  text-align: center;
  p {
    margin: 50px 0;
    font-size: 18px;
    font-weight: bold;
  }
  .btnSure {
    margin-right: 100px;
    margin-bottom: 20px;
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
